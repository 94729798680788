import { clearCoupon } from "./coupons";


const PLACE_ORDER = "cart/PLACE_ORDER";


const placeOrder = (cart) => ({
    type: PLACE_ORDER,
    cart
})



export const placeOrderThunkPayInStore = (id, paymentOption, name, phone, tip, address, cartItems, coupon, freeItem,time , rewardItem) => async (dispatch) => { 
    
    const response = await fetch(`https://hutaoadmin.onrender.com/api/orders/${id}`, { 
        method: 'POST',
        body: JSON.stringify({paymentOption, name, phone, tip, address, cartItems, coupon, freeItem, schduleTime: time, rewardItem}),
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
        },
        credentials: 'include',

    })
    
    if (response.ok) { 
        const data = await response.json();
        dispatch(placeOrder(data))
        localStorage.removeItem('cart')
        localStorage.removeItem('freeItem')
        localStorage.removeItem('rewardItem')
        dispatch(clearCoupon())
    }
}


const initialState = {
  cart: [],
  order: {}
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
        case PLACE_ORDER:
            return {
                ...state,
                order: action.cart,
                cart: []
            };
      default:
          return state;
  }
};
  
  export default orderReducer;
  