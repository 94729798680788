const GET_POINTS = 'GET_POINTS';
const BUY_POINTS = 'BUY_POINTS';
const GET_REWARDS = 'GET_REWARDS';

export const getRewards = () => {
    return {
        type: GET_POINTS,
    };
    }
    export const sendVerificationCodeThunk = (phone) => async (dispatch) => {
        const response = await fetch('https://hutaoadmin.onrender.com/api/rewards/send-verification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone }),
        });

        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            console.error('Failed to send verification code:', response.statusText);
            return null;
        }
    };

    export const verifyCodeThunk = (phone, code) => async (dispatch) => {
        const response = await fetch('https://hutaoadmin.onrender.com/api/rewards/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone, code }),
        });

        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            console.error('Failed to verify code:', response.statusText);
            return null;
        }
    };
export const buyPoints = () => {
    return {
        type: BUY_POINTS,
    };
    }

export const getAllRewards = (rewards) => {
    return {
        type: GET_REWARDS,
        rewards,
    };
    }

    export const getRewardsThunk = (restaurantId, phone) => async (dispatch) => {
        const response = await fetch(`https://hutaoadmin.onrender.com/api/rewards/${restaurantId}/user?phone=${phone}`);
    
        if (response.ok) {
            const rewards = await response.json();
            dispatch(getRewards(rewards));
            return rewards; // Return the rewards data
        } else {
            console.error('Failed to fetch rewards:', response.statusText);
            return null; // Handle failed requests gracefully
        }
    };
    

export const buyPointsThunk = (restaurantId, phone, name, total) => async (dispatch) => {
    const response = await fetch(`https://hutaoadmin.onrender.com/api/rewards/${restaurantId}/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone, name, total}),
    });

    if (response.ok) {
        dispatch(getRewardsThunk(restaurantId));
    }
}

export const getAllRewardsThunk = (restaurantId) => async (dispatch) => {
    const response = await fetch(`https://hutaoadmin.onrender.com/api/rewards/restaurant/${restaurantId}`);

    if (response.ok) {
        const rewards = await response.json();
        dispatch(getAllRewards(rewards));
    }
}

const initialState = {
    points: {},
    rewards: []
};

const rewardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POINTS:
            return {
                ...state,
                points: action.rewards
            };
        case GET_REWARDS:
            return {
                ...state,
                rewards: action.rewards
            };
        default:
            return state;
    }
};

export default rewardsReducer;
