import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRewardsThunk } from "../store/rewards";
import Context from "../Context";
import AddRewardItemModal from "./AddRewardItemModal";
import { sendVerificationCodeThunk, verifyCodeThunk } from "../store/rewards";

export function RewardModal({ isOpen, onClose, points, phone, handlePhoneChange }) {
  const dispatch = useDispatch();
  const rewards = useSelector((state) => state.rewards.rewards);
  const { restaurantId, changedCart } = useContext(Context);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addFreeItemModal, setAddFreeItemModal] = useState(false);
  const [currentRewardItem, setCurrentRewardItem] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [verificationStep, setVerificationStep] = useState(1); // 1: Send Code, 2: Verify Code
  const [verificationMessage, setVerificationMessage] = useState('');
  const [choosedReward, setChoosedReward] = useState(null);

  useEffect(() => {
    setCurrentRewardItem(JSON.parse(localStorage.getItem('rewardItem')) || false);
  }, [changedCart]);

  useEffect(() => {
    dispatch(getAllRewardsThunk(restaurantId));
  }, [dispatch, restaurantId]);

  const handleRedeem = async (reward) => {
    const verifiedPhone = JSON.parse(localStorage.getItem('verifiedPhone')) || false;

    if (!verifiedPhone) {
      setShowVerifyModal(true); // Show verification modal
      setChoosedReward(reward);
    } else {
      setSelectedItem(reward.Item);
      setAddFreeItemModal(true);
    }
  };

  const handleSendCode = async () => {
    const sendResult = await sendVerificationCodeThunk(phone)(dispatch);
    if (sendResult && sendResult.message === 'success') {
      setVerificationStep(2); // Move to code verification step
      setVerificationMessage('Verification code sent to your phone.');
    } else {
      setVerificationMessage('Failed to send verification code. Please try again.');
    }
  };

  const handleVerifyCode = async (userCode) => {
    const verifyResult = await verifyCodeThunk(phone, userCode)(dispatch);
    if (verifyResult && verifyResult.message === 'success') {
      localStorage.setItem('verifiedPhone', JSON.stringify(true));
      setVerificationMessage('Phone verified successfully!');
      setTimeout(() => {
        setShowVerifyModal(false); // Hide verification modal
        setSelectedItem(choosedReward.Item);
        setAddFreeItemModal(true);

      }, 1000);
    } else {
      setVerificationMessage('Verification failed. Please try again.');
    }
  };

  if (!isOpen) return null;
  const formatPhoneNumber = (phone) => {
    if (phone.length > 6) {
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
    } else if (phone.length > 3) {
      return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
    } else if (phone.length > 0) {
      return `(${phone}`;
    }
    return '';
  };

  return (
    <div className="reward-modal">
      {!showVerifyModal && (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="text-center mb-2 mt-2 w-100">
                <h6 className="fs-5">
                  Your Points: <span className="badge bg-success">{points}</span>
                </h6>
                <div className="col-12 col-md-12 mb-3">
                  <input
                   type="tel"
                    className="form-control text-center"
                    id="phone"
                    value={formatPhoneNumber(phone)} // Display formatted value
                    onChange={handlePhoneChange} // Update raw phone value
                    placeholder="(123) 456-7890"
                    required
                    maxLength={14} // To account for display formatting
                  />
                </div>
                <p className="text-danger">Only one reward item can be redeemed per order.</p>
              </div>
            </div>
            <div
              className="modal-body custom-scrollbar"
              style={{ maxHeight: '600px', overflowY: 'auto' }}
            >
              <div className="list-group">
                {rewards.map((reward) => (
                  <div className="reward-item" key={reward.id}>
                    <div className="reward-item-img">
                      <img
                        src={
                          reward.Item.image_url ||
                          'https://theme-assets.getbento.com/sensei/a342b2d.sensei/assets/images/catering-item-placeholder-704x520.png'
                        }
                        alt={reward.Item.name}
                        className="img-fluid"
                      />
                    </div>
                    <div className="reward-item-details">
                      <h5 className="reward-item-title">{reward.Item.name}</h5>
                      <p className="reward-item-description">
                        {reward.Item.description !== 'null'
                          ? reward.Item.description
                          : 'No description available.'}
                      </p>
                      <p className="reward-item-points">
                        <strong>Points Needed:</strong> {points}/{reward.need_amount}
                      </p>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleRedeem(reward)}
                        disabled={
                          Number(points) < Number(reward.need_amount) ||
                          (currentRewardItem && currentRewardItem.Item.id === reward.Item.id)
                        }
                      >
                        {currentRewardItem && currentRewardItem.Item.id === reward.Item.id
                          ? 'Selected Reward'
                          : 'Redeem'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <p>
                {currentRewardItem
                  ? 'Current Reward: ' + currentRewardItem.Item.name
                  : 'No reward selected '}
              </p>
              <button type="button" className="btn btn-success" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

{showVerifyModal && (
  <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Phone Verification</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShowVerifyModal(false)}
          ></button>
        </div>
        <div className="modal-body">
          {verificationStep === 1 && (
            <div className="text-center">
              <p className="mb-3">We need to verify your phone number to redeem points:</p>
              <button className="btn btn-primary" onClick={handleSendCode}>
                Send Code
              </button>
            </div>
          )}
          {verificationStep === 2 && (
            <div className="text-center">
              <p className="mb-3">Enter the code sent to your phone:</p>
              <input
                type="tel"
                className="form-control text-center w-50 mx-auto"
                placeholder="Enter code"
                maxLength={6}
                onChange={(e) => {
                  const code = e.target.value;
                  if (code.length === 6) {
                    handleVerifyCode(code);
                  }
                }}
              />
            </div>
          )}
          {verificationMessage && (
            <p className="mt-3 text-success text-center">{verificationMessage}</p>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowVerifyModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}


      <AddRewardItemModal
        modal={addFreeItemModal}
        setModal={() => setAddFreeItemModal(false)}
        item={selectedItem}
      />
    </div>
  );
}
